.about {
  background-color: #06417B;
  margin: auto;
  padding: 5rem 1rem 5rem 2rem;
  width: 100%;
}

.about .container {
  max-width: 1120px;
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, auto);
}

.about .container img {
  padding: 10px;
  width: 25rem;
  border-radius: 30px;
}

.about h2 {
  font-size: 3rem;
}

.about .col-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  color: white;
}

.about .col-2 p:first-of-type {
  margin: 2rem 0;
  font-size: 14px;
  font-weight: 300;
}

.about .col-2 p:nth-child(4) {
  font-size: 1.8rem;
  font-style: italic;
  font-weight: 600;
}

.btn {
  margin-top: 1rem;
}

@media screen and (max-width:940px) {
  .about .container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .about .container .col-2 {
    padding: 0;
  }

  .about .container img {
    padding: 10px;
    width: 20rem;
    border-radius: 30px;
  }

  .btn {
    margin-bottom: 1rem;
  }
}








